const Loader = () => (
  <progress
    className='flex justify-center gap-2 align-middle'
    data-testid='loader'
    aria-label='Loading'
    aria-live='polite'
    aria-busy='true'
  >
    <div className='rounded-full w-4 h-4 animate-loader bg-blue-l3 my-2' />
    <div className='rounded-full w-4 h-4 animate-loader animation-delay: 0.2s bg-blue-l3 my-2' />
    <div className='rounded-full w-4 h-4 animate-loader animation-delay: 0.4s bg-blue-l3 my-2' />
  </progress>
);

export default Loader;
